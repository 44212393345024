// SPDX-FileCopyrightText: © 2021 Tech and Software Ltd.
// SPDX-License-Identifier: AGPL-3.0-only OR LicenseRef-uk.ltd.TechAndSoftware-1.0

import { Teletext, Attributes, Colour, Level } from '@techandsoftware/teletext';

const PAGES = [
    // wikifax spoof page
    'QIECBAgQV9OvTmw-EDFgwQIKmjry5oIPDkgZMkDFwwYOmDAugQNEDRogaIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIAh0uqYOmDrRowJGjxg80ZNGDIHqaMqDNyy5UCBAgQIECBAgCHS6DSh0odWpSwat0HfU61atYfLux-cezfwy5NOFAgQIECAovXr1q9avWrV65cuXrFq5atWr169evXr169evXr169evXr0CBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgDRuW_ag6b8mHyn5oM2XD068suRBh5dNOPZlQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIAcXDy6aA-nmg6aMqDpo08siDhsw7svRBm5b9qDpoyoOfXcgQYd2RB00ZUG_ds8oMPPpy37t-3Tjw7EG_Fqy4-iDXu399yBB03oNGHli39eSDZpzZVyCT0QaeaDpoyoMmXdzy8-iBAgQIEHDZh3ZeiDTuQdNGVBz37MPJBz88-mXagw7siDbv59ECBAgQbcPPnp7ZUG_Mg6aMqDNv68kHLfj1-UHDZh3ZenNcgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIEEHFv69EDJylQb8yCLh5dNCfmg59eWbDjyoNPNBsw7sixAgQd9PTQg6aMqDll24dO7Jl5IMe_tl5ZciDTuQd8PTLyQYd2RBt649CDfmQRcPLpoT80HDfsw8kHLLn0793NBj39svLLkQIEGncg048q5AgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBBFw8umhBm38tuXIg39svJA0XNUGLTs2ad-5B5y4eXNAgQIEGHPvXIK-npo07kHTRlQZtPLn0QYtOzZp37kHnLh5c1iBAgQbNObKgw8OGXDyy5EGncg6aMqDfjy4d3NBh3ZEGLLnw7kCBB03oMObNlx9EEXDy6aE_NBh6bd_Phoy8sqDDuyIECBAgQIEHPryzYceVcgQIEAIxM048u7nlQU6ESwghw1sKytpwVrNcwQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECBAgQIECA',
    // C4 Teletext screen kindly from Teletext Archeologist
    'QIECBAgQIAh0HUy7MvTL46B0Bxo5YIJXXcxcg2LF0xYOmDEn_a_8XPBw_teHDgHQLkCBAgQIFyBAgJeF69ev-Mv__6HQICf9_31f9Tf-1_9-YdAgQIFyBAgQIC6BB4__v_Ql9__24dAuJ_2v_V_1ff7X5-_h0C5AgQIECBAuDri__v3__yX_WnDoFyAj8-fPnz58-fPnz5-____z5wYIECAugQK___-nJf0CBX8YICP_______________________9-cA64krWLFi7__QIEGv6gIrFi1evWL1ixYvXrlqxYvWLFixY0-sA65ASV7__9AgQfn6AO0csQ1SrSnRaSemgqRYNOLSQEiLX__6EkGhPt1_0CDR_SoA7RyyDRqUGbFQSaiFAgQIECBAgItf__-0JKuj7__aIP_9ggA4MGDBgwYMGDBgwYMGDBgwYMGAi_____8kgQev__qgVf_7AO0ctQdCLOQUIMymgUN1rFkpQICP_____2pLB_____1hq_tQODBgwYMGDBgwYMGDBgwYMGDBgIvf_____kvX_____0_r-lDtHLYHUq0p0WkgqSIqCvPpTUCAi1To0aMl6____9P4_f_6ANC5ZcOvNh59EHTTtyoMnLDtwkkGD5___8P____683___wtAODBgwYMGDBgwYMGDBgwYMGDAS2v06NWl_f___-y___7f1_DtHLcHXpSakVBJqIUBhutYslJIjw-fSWr_______9_4_P_8NXyoNGHIg6b0GzD1z6Fy5cgQICP3__Jf______________wODBgwYMGDBgwYMGDBgwYMGDBgI9f_8l_______________DtHLgHHmVY6emglT5cWmgQIECAi1foySv-_Xr_69f____68O0cuQcuTEpoIkGygn1aiBAgJICLUl-0t_2hv-0________wODBgwYMGDBgwYMGDBgwYMGDBgIpSStElVokqtEvDoECBAgQIEAaTzQed_Xkg59N_Lyg37kHTRlQc8fLLl3Pw7Ry3QIECACdBjUEGZMQWZ9Wkgg0qiCvPpS0E-cgiSadCZBsoODRyyQIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA0OWVbzx6N-_YCjcsO3Kgk9Acrfry8w0bruQJkEfDty80CA',
];

// The following arrays are used to cycle through values
const ASPECT_RATIOS = [1.2, 1.22, 1.33, 'natural'];
const FONTS = ['sans-serif', 'Bedstead', 'native', 'serif', 'Unscii', 'Ubuntu', '"Roboto Mono"', 'monospace', 'cursive'];
const CHARACTER_SETS = [
    'g0_latin',
    'g0_latin__czech_slovak',
    'g0_latin__english',
    'g0_latin__estonian',
    'g0_latin__french',
    'g0_latin__german',
    'g0_latin__italian',
    'g0_latin__latvian_lithuanian',
    'g0_latin__polish',
    'g0_latin__portuguese_spanish',
    'g0_latin__romanian',
    'g0_latin__serbian_croatian_slovenian',
    'g0_latin__swedish_finnish_hungarian',
    'g0_latin__turkish',
    'g0_greek',
    'g0_cyrillic__russian_bulgarian',
    'g0_cyrillic__serbian_croatian',
    'g0_cyrillic__ukranian',
    'g0_arabic',
    'g0_hebrew',
];
const G2_CHARACTER_SETS = [
    'g2_greek',
    'g2_cyrillic',
    'g2_arabic',
    'g2_latin',
];
const VIEWS = ['classic__graphic-for-mosaic', 'classic__font-for-mosaic'];

class DemoApp {
    constructor() {
        this._ttx = Teletext();
        this._ttx.setDefaultG0Charset('g0_latin__english');
        this._ttx.addTo('#teletextscreen');
        this._ttx.setHeight(500);
        this._ttx.showTestPage();

        this.KEY_EVENTS = {
            '?': 'ttx.reveal',
            'm': 'ttx.mix',
            's': 'ttx.subtitlemode',
        };

        this._aspectRatioIndex = 0;
        this._fontIndex = 0;
        this._charSetIndex = 0;
        this._g2CharSetIndex = 0;
        this._viewIndex = 0;
        this._pageIndex = 0;
        this._smoothPluginIsLoaded = false;

        this._initEventListeners();
    }

    _initEventListeners() {
        window.addEventListener('keypress', e => handleKeyPress.call(this, e));

        window.addEventListener('DOMContentLoaded', () => {
            document.getElementById('revealButton').addEventListener('click', () => {
                window.dispatchEvent(new Event('ttx.reveal'));
            });
            document.getElementById('levelSelect').addEventListener('input', e => {
                this._ttx.setLevel(Level[e.target.value]);
            });
        });

        document.querySelectorAll('[data-key]').forEach(e => {
            e.addEventListener('click', () => handleKeyPress.call(this, { key: e.dataset.key }))
        });
    }
}

async function handleKeyPress(e) {
    // code below demos the API on the teletext object
    // API documentation at @techandsoftware/teletext on npm
    switch (e.key) {
        case '?': // reveal
            window.dispatchEvent(new Event(this.KEY_EVENTS[e.key]));
            // Reveal, mix and boxed could use the API on the teletext instance instead of dispatching an event.
            // The event is useful if the app code needs to remain decoupled from the teletext instance
            break;
        case 'm': // mix
            window.dispatchEvent(new Event(this.KEY_EVENTS[e.key]));
            break;
        case 's': // boxed mode (subtitles)
            window.dispatchEvent(new Event(this.KEY_EVENTS[e.key]));
            break;
        case 't':
            this._ttx.showTestPage();
            break;
        case 'x':
            this._ttx.showRandomisedPage();
            break;
        case 'c': // for cells
            this._ttx.toggleGrid();
            break;

        case 'a': // rotate through aspect ratios
            this._aspectRatioIndex++;
            if (this._aspectRatioIndex == ASPECT_RATIOS.length) this._aspectRatioIndex = 0;
            this._ttx.setAspectRatio(ASPECT_RATIOS[this._aspectRatioIndex]);
            break;

        case 'd': // display a page using strings
            writePage(this._ttx);
            break;

        case 'f': // rotate through fonts
            this._fontIndex++;
            if (this._fontIndex == FONTS.length) this._fontIndex = 0;
            console.debug('setting font to', FONTS[this._fontIndex]);
            this._ttx.setFont(FONTS[this._fontIndex]);
            break;

        case 'w': // for wipe
            this._ttx.clearScreen();
            break;

        case 'h':
            this._ttx.setHeight(document.documentElement.clientHeight * 0.8);
            break;

        case 'e': // e for encoding - rotate through g0 character sets
            this._charSetIndex++;
            if (this._charSetIndex == CHARACTER_SETS.length) this._charSetIndex = 0;
            this._ttx.setDefaultG0Charset(CHARACTER_SETS[this._charSetIndex], true);
            break;

        case 'v': // switch views which changes the mosaic rendering method
            this._viewIndex++;
            if (this._viewIndex == VIEWS.length) this._viewIndex = 0;
            this._ttx.setView(VIEWS[this._viewIndex]);
            this._smoothPluginIsLoaded = false;
            break;

        case 'p': // load or remove the plugin to smooth mosaic graphics
            if (this._smoothPluginIsLoaded) {
                this._ttx.setView(VIEWS[this._viewIndex]); // resetting the view removes the plugin
                this._smoothPluginIsLoaded = false;
            } else if (this._viewIndex == 0) {  // plugin works on the graphical mosaic view
                // Loading the plugin as a dynamic import as it's large
                // This also avoids having to bundle it with the application lib at build time
                try {
                    const module = await import('@techandsoftware/teletext-plugin-smooth-mosaic');
                    this._ttx.registerViewPlugin(module.SmoothMosaicPlugin);
                    this._smoothPluginIsLoaded = true;
                } catch (e) {
                    console.error('DemoApp: Failed to use smooth mosaic plugin: import failed:', e.message);
                }
            }
            break;

        case 'l': // load a page using the base64-packed format used by edit.tf
            this._pageIndex++;
            if (this._pageIndex == PAGES.length) this._pageIndex = 0;
            this._ttx.loadPageFromEncodedString(PAGES[this._pageIndex]);
            break;

        case 'z': // siZe
            this._ttx.clearScreen();
            writePageWithSizeAttributes(this._ttx);
            break;

        case 'n': // eNhancements
            writeEnhancements(this._ttx);
            break;
        
        case 'o': // encOding - set g2 set
            this._g2CharSetIndex++;
            if (this._g2CharSetIndex == G2_CHARACTER_SETS.length) this._g2CharSetIndex = 0;
            this._ttx.setG2Charset(G2_CHARACTER_SETS[this._g2CharSetIndex], true);
            break;

        case 'u': // oUtputLine
            this._ttx.clearScreen();
            writePageFromOutputLines(this._ttx);
            break;

        default:
    }
}

function writePageFromOutputLines(teletext) {
    const lines = [];

    // page from Teefax created by Peter Kwan
    lines.push("OL,0,XXXXXXXXTEEFAX mpp DAY dd MTH C hh:nn.ss")
    lines.push("OL,1,Q pppRpppTpppR||,,,<,,<,,<,,|,,,|,l<,|||")
    lines.push("OL,2,Q 5|jR5|jT5|jR]Q//jsjsjshs4ouz?   ")
    lines.push("OL,3,Q 5/jR5/jT5/jR]Q  jpjpj j 55j   ")
    lines.push("OL,4,Q ###R###T###R##########################")
    lines.push("OL,5,CNews                                   ")
    lines.push("OL,6,AM TEEFAX: QUALITY TELETEXT SINCE 2015  ")
    lines.push("OL,8,F```````````````````````````````````````")
    lines.push("OL,9,BWHAT'S NEW....G101FBAMBOOZLE.......G150")
    lines.push("OL,10,BTV LISTINGS...G620FVIEWERS' PAGES..G200")
    lines.push("OL,11,BBBC NEWS......G104FREVIEWS.........G400")
    lines.push("OL,12,BUK WEATHER....G401FMr Biffo........G570")
    lines.push("OL,13,BLA ON THE QT..G160FUKEFAX..........G600")
    lines.push("OL,15,AARTS & ENT....G500CTTXT REPLICAS...G670")
    lines.push("OL,16,ACarlos' Art...G501CENGINEERING INFOG700")
    lines.push("OL,17,ADan's Art.....G510CRASPBERRY Pi....G785")
    lines.push("OL,18,ASteve Horsley G520CTESTCARDS INDEX G790")
    lines.push("OL,19,ACY2...........G530CTELETEXT EVENTS G800")
    lines.push("OL,20,AChris TsangariG540CBlock Party 2021G810")
    lines.push("OL,21,ARaquel Meyers G555CDigitiser.......G470")
    lines.push("OL,23,V]!D!DIGITISER! Mr.Biffo!  Pages 571-574")
    lines.push("OL,24,A About BBamboozle CEvents FArt Archive")
    teletext.setPageFromOutputLines(lines);
}

function writeEnhancements(teletext) {
    const e = teletext.enhance();
    let code = 0x20;
    for (let r = 0; r < 6; r++) {
        for (let c = 0; c < 16; c++) {
            e.pos(2 + c, 1 + r).putG1(String.fromCharCode(code));
            e.pos(2 + c, 8 + r).putG2(String.fromCharCode(code));
            e.pos(2 + c, 15 + r).putG3(String.fromCharCode(code));
            code++;
        }
    }
    const chars = "ACEINOUSZaceinousz";
    for (let charIndex = 0; charIndex < chars.length; charIndex++) {
        for (let diacritic = 0; diacritic < 16; diacritic++) {
            e.pos(19 + charIndex, 3 + diacritic).putG0(chars.charAt(charIndex), diacritic);
        }
    }
    e.end();
}

// Writes a page using the setPageRows API and helper functions in Attributes.
// Character control codes can be used directly instead of the Attributes object
function writePage(teletext) {
    teletext.setPageRows([
        'This is teletext level 1.5  ETSI 300 706',
        Attributes.charFromTextColour(Colour.YELLOW) + '   40 columns' + Attributes.charFromTextColour(Colour.WHITE) + '\u{7f}' + Attributes.charFromTextColour(Colour.YELLOW) + '25 rows' + Attributes.charFromTextColour(Colour.WHITE) + '\u{7f}' +  Attributes.charFromTextColour(Colour.YELLOW) + '8 colours',
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + ' ' +
            Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.BLUE) + '    96',
        'abcdefghijklmnopqrstuvwxyz' + ' ' +
            Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.BLUE) + 'characters',
        '0123456789012345678901234567890123456789',
        ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~\u007f',
        '````````````````````````````````````````',
        'This is' + Attributes.charFromTextColour(Colour.WHITE)   + 'white text!  ' + Attributes.charFromTextColour(Colour.YELLOW) + 'yellow text!',
        '     ' + Attributes.charFromAttribute(Attributes.START_BOX) + Attributes.charFromAttribute(Attributes.START_BOX) + Attributes.charFromTextColour(Colour.CYAN)    + 'cyan text!   ' + Attributes.charFromTextColour(Colour.GREEN)  + 'green text!' + Attributes.charFromAttribute(Attributes.END_BOX),
        '      ' + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.MAGENTA) + 'magenta text!' + Attributes.charFromTextColour(Colour.RED)    + 'red text!   ' + Attributes.charFromAttribute(Attributes.BLACK_BACKGROUND),
        '      ' + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.BLUE)    + 'blue text!  '   + Attributes.charFromTextColour(Colour.WHITE) + Attributes.charFromTextColour(Colour.BLACK) + 'black text! '  + Attributes.charFromAttribute(Attributes.BLACK_BACKGROUND),
        Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + '   ' +
            Attributes.charFromTextColour(Colour.YELLOW)  + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + '   ' +
            Attributes.charFromTextColour(Colour.CYAN)    + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + '   ' +
            Attributes.charFromTextColour(Colour.GREEN)   + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + '   ' +
            Attributes.charFromTextColour(Colour.MAGENTA) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + '   ' +
            Attributes.charFromTextColour(Colour.RED)     + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + '   ' +
            Attributes.charFromTextColour(Colour.BLUE)    + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + '   ' +
            Attributes.charFromTextColour(Colour.BLACK)   + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + '   ',
        Attributes.charFromGraphicColour(Colour.WHITE) + '    !"#$%&\'()*+,-./' +  Attributes.charFromTextColour(Colour.WHITE) + "  64 graphic",
        Attributes.charFromTextColour(Colour.RED) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromGraphicColour(Colour.WHITE) + ' 0123456789:;<=>?' + Attributes.charFromTextColour(Colour.WHITE) + '  characters',
        Attributes.charFromGraphicColour(Colour.WHITE) + '   `abcdefghijklmno',
        Attributes.charFromGraphicColour(Colour.WHITE) + ' ' + Attributes.charFromAttribute(Attributes.START_BOX) + Attributes.charFromAttribute(Attributes.START_BOX) + 'pqrstuvwxyz{|}~\u007f' + Attributes.charFromTextColour(Colour.WHITE) + Attributes.charFromAttribute(Attributes.CONCEAL) + ' Concealed',
        Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) + Attributes.charFromGraphicColour(Colour.WHITE) +  '   !"#$%&\'()*+,-./'  +  Attributes.charFromTextColour(Colour.WHITE) + Attributes.charFromAttribute(Attributes.FLASH) + ' Flashing!' + Attributes.charFromAttribute(Attributes.STEADY) + '[',
        Attributes.charFromTextColour(Colour.RED) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) + Attributes.charFromGraphicColour(Colour.WHITE) +  '0123456789:;<=>?' + Attributes.charFromTextColour(Colour.WHITE) + Attributes.charFromAttribute(Attributes.CONCEAL) + '(")>' + Attributes.charFromAttribute(Attributes.FLASH) + 'Both',
        Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) + Attributes.charFromGraphicColour(Colour.WHITE) +  '  `abcdefghijklmno',
        Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) + Attributes.charFromGraphicColour(Colour.WHITE) +  '  pqrstuvwxyz{|}~\u007f',
        Attributes.charFromTextColour(Colour.BLUE) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.WHITE) +  'Normal' + Attributes.charFromAttribute(Attributes.DOUBLE_HEIGHT) + 'Double height gjpqy' + Attributes.charFromGraphicColour(Colour.WHITE) + '\u0024\u007b' +  Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) + '\u0024\u007b' + Attributes.charFromAttribute(Attributes.NORMAL_SIZE) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.BLUE) + '\u{7f}\u{7f}',
        'This text should not be visible', // the row below a row containing double height is hidden
    
        "Hold " +                                                        // character displayed is:
            Attributes.charFromAttribute(Attributes.HOLD_MOSAICS) +        // space
            Attributes.charFromGraphicColour(Colour.GREEN) +               // space
            '9' +                                                          // contiguous mosaic
            Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) +  // held contiguous mosaic from character before
            '9' +                                                          // separated mosaic
            Attributes.charFromAttribute(Attributes.CONTIGUOUS_GRAPHICS) + // held separated mosaic
            '99' +                                                          // contiguous mosaic
            ' ' +                                                          // space
            Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) +  // space
            '9' +                                                          // separated mosaic
            Attributes.charFromAttribute(Attributes.CONTIGUOUS_GRAPHICS) + // held separated mosaic
            '9' +                                                          // contiguous mosaic
            Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) +  // held contiguous mosaic
            '99' +                                                          // separated mosaic
            ' ' +                                                          // space
            Attributes.charFromGraphicColour(Colour.WHITE) +               // (held) space
            '9' +                                                          // separated mosaic        white
            Attributes.charFromGraphicColour(Colour.YELLOW) +              // held separated mosaic   white
            Attributes.charFromGraphicColour(Colour.CYAN) +                // held separated mosaic   yellow
            Attributes.charFromGraphicColour(Colour.GREEN) +               // held separated mosaic   cyan
            Attributes.charFromGraphicColour(Colour.MAGENTA) +             // held separated mosaic   green
            Attributes.charFromGraphicColour(Colour.RED) +                 // held separated mosaic   magenta
            Attributes.charFromGraphicColour(Colour.BLUE) +                // held separated mosaic   red
            Attributes.charFromAttribute(Attributes.CONTIGUOUS_GRAPHICS) + // held separated mosaic   blue
            '9' +                                                          // contiguous mosaic       blue
            Attributes.charFromGraphicColour(Colour.RED) +                 // held contiguous mosaic  blue
            Attributes.charFromGraphicColour(Colour.MAGENTA) +             // held contiguous mosaic  red
            Attributes.charFromGraphicColour(Colour.GREEN) +               // held contiguous mosaic  magenta
            Attributes.charFromGraphicColour(Colour.CYAN) +                // held contiguous mosaic  green
            Attributes.charFromGraphicColour(Colour.YELLOW) +              // held contiguous mosaic  cyan
            Attributes.charFromGraphicColour(Colour.WHITE) +               // held contiguous mosaic  yellow
            '99',
        "mosaics" + Attributes.charFromTextColour(Colour.BLUE) + "^ ^     ^ ^     ^^^^^^^ ^^^^^^"

    ]);
}

function writePageWithSizeAttributes(teletext) {
    teletext.setPageRows([
        Attributes.charFromAttribute(Attributes.DOUBLE_WIDTH) + 'D o u b l e   w i d t h',
        Attributes.charFromGraphicColour(Colour.WHITE) + Attributes.charFromAttribute(Attributes.DOUBLE_WIDTH) + 'g r a p h i c s' + Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) + '  s e p a r a t e d',
        Attributes.charFromTextColour(Colour.GREEN) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromGraphicColour(Colour.WHITE) + Attributes.charFromAttribute(Attributes.DOUBLE_WIDTH) + 'g r a p h i c s' + Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) + '  s e p a r a t e d',
        '0123456789012345678901234567890123456789',
        ' ' + Attributes.charFromAttribute(Attributes.DOUBLE_WIDTH) + 'D' +
            Attributes.charFromTextColour(Colour.BLUE) +'o' +
            Attributes.charFromTextColour(Colour.RED) +'u' +
            Attributes.charFromTextColour(Colour.MAGENTA) +'b' +
            Attributes.charFromTextColour(Colour.GREEN) +'l' +
            Attributes.charFromTextColour(Colour.YELLOW) +'e' +
            Attributes.charFromTextColour(Colour.YELLOW) +' ' +
            Attributes.charFromTextColour(Colour.CYAN) +'w' +
            Attributes.charFromTextColour(Colour.YELLOW) +'i' +
            Attributes.charFromTextColour(Colour.GREEN) +'d' +
            Attributes.charFromTextColour(Colour.MAGENTA) +'t' +
            Attributes.charFromTextColour(Colour.RED) +'h',
        '01234567890123456789',
        Attributes.charFromTextColour(Colour.RED) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.YELLOW) + Attributes.charFromAttribute(Attributes.DOUBLE_WIDTH) + 'R e d' + Attributes.charFromAttribute(Attributes.BLACK_BACKGROUND) + 'B a c k g r o u n d',
        Attributes.charFromAttribute(Attributes.START_BOX) + Attributes.charFromAttribute(Attributes.START_BOX) + Attributes.charFromAttribute(Attributes.DOUBLE_WIDTH) + 'B o x e d' + Attributes.charFromAttribute(Attributes.END_BOX) + 'U n b o x e d',
        Attributes.charFromTextColour(Colour.RED) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.YELLOW) + Attributes.charFromAttribute(Attributes.DOUBLE_WIDTH) + '2 * w' + Attributes.charFromAttribute(Attributes.NORMAL_SIZE) + 'Normal' + Attributes.charFromAttribute(Attributes.DOUBLE_HEIGHT) + '2 * h',
        'row hidden',
        Attributes.charFromAttribute(Attributes.START_BOX) + Attributes.charFromAttribute(Attributes.START_BOX) + 'Boxed' + Attributes.charFromTextColour(Colour.RED) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.YELLOW) + Attributes.charFromAttribute(Attributes.DOUBLE_WIDTH) + '2 * w' + Attributes.charFromAttribute(Attributes.NORMAL_SIZE) + 'Normal' + Attributes.charFromAttribute(Attributes.DOUBLE_HEIGHT) + '2 * h',
        'row hidden',
        Attributes.charFromAttribute(Attributes.DOUBLE_SIZE) + 'D o u b l e ' + 
            Attributes.charFromTextColour(Colour.RED) + ' s i z e' +
            Attributes.charFromAttribute(Attributes.DOUBLE_HEIGHT) + 'height' +
            Attributes.charFromAttribute(Attributes.NORMAL_SIZE) + 'normal',
        'row hidden',
        Attributes.charFromTextColour(Colour.MAGENTA) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromTextColour(Colour.WHITE) + Attributes.charFromAttribute(Attributes.DOUBLE_SIZE) + 'D o u b l e ' + 
            Attributes.charFromTextColour(Colour.YELLOW) + ' s i z e' +
            Attributes.charFromAttribute(Attributes.DOUBLE_HEIGHT) + 'height' +
            Attributes.charFromAttribute(Attributes.NORMAL_SIZE) + 'normal',
        'row hidden',
        Attributes.charFromTextColour(Colour.GREEN) + Attributes.charFromAttribute(Attributes.NEW_BACKGROUND) + Attributes.charFromGraphicColour(Colour.BLUE) + Attributes.charFromAttribute(Attributes.DOUBLE_SIZE) + 'g r a p h i c s' + Attributes.charFromAttribute(Attributes.SEPARATED_GRAPHICS) + '  s e p a r a t e d',
        'row hidden',
        Attributes.charFromAttribute(Attributes.START_BOX) + Attributes.charFromAttribute(Attributes.START_BOX) + Attributes.charFromAttribute(Attributes.DOUBLE_SIZE) + 'B o x e d' + Attributes.charFromAttribute(Attributes.END_BOX) + 'U n b o x e d',
    ]);
}

// run the app
new DemoApp();
